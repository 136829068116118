const moduleName = "dsa"

export default {
  name : moduleName,
  api : {
    server : "https://appservice.magbizcloud.com/",   //"http://192.168.1.103:8081/",  //
    app_module : moduleName,
    timeout: 60000
  },
  title : "DSA System",
  description : "Digital sales automation system",
  //logo : "assets/"+moduleName+"/logo.png",                // 128*128 Color Logo
  logo_head : "assets/"+moduleName+"/logo-head.png",      // 200*34 Color Logo
  logo_head_text : "DSA System",
  theme : {
    primary: {
      //light: "#2ECC71",
      //main: "#229954",
      //dark: "#1E8449",
      light: "hsl(200, 100%, 65%)",
      main: "hsl(200, 100%, 50%)",
      dark: "hsl(200, 100%, 48%)",
      contrastText: '#fff'
    },
    secondary: {
      light: "rgb(255,255,255,0.9)",
      main: "rgb(255,255,255,0.95)",
      dark: "rgb(255,255,255,1)",
      contrastText: '#424242'
    },
    mytheme: {
      button: "#5F75B4",
      input: "#F4EFE7"
    }
  },
  menu:{
    dashboard: {
  		list: [
        {"menu_title": "sidebar.dashboard", "menu_icon": "view-dashboard", "path": "/"+moduleName+"/dashboard/main"},
      ]
    },
    main_menu: {
      //title: "sidebar.section.settings",
  		list: [
        {"menu_title": "sidebar.company_settings","menu_icon": "settings", allowUserRole:["admin"],"child_routes": [
          {"menu_title": "sidebar.settings_company","path": "/"+moduleName+"/settings/company",},
          {"menu_title": "sidebar.settings_payment","path": "/"+moduleName+"/settings/payment",},
        ]},
        {"menu_title": "sidebar.supplier","menu_icon": "city-alt", allowUserRole:["admin","it","account","account_h","purchase","purchase_h"],"child_routes": [
          {"menu_title": "sidebar.contact_supplier", "path": "/"+moduleName+"/contact/supplier"},
        ]},
        {"menu_title": "sidebar.store","menu_icon": "store", allowUserRole:["admin","it","sale","sale_h","account","account_h"],"child_routes": [
          {"menu_title": "sidebar.contact_customershop", "path": "/"+moduleName+"/contact/customershop"},
          {"menu_title": "sidebar.location_shipto", "path": "/"+moduleName+"/location/shipto",},
          {"menu_title": "sidebar.contact_shop_type", "path": "/"+moduleName+"/contact/shop_type"},
          {"menu_title": "sidebar.pos_posshop", "path": "/"+moduleName+"/pos/posshop"},
        ]},
        {"menu_title": "sidebar.contact", "menu_icon": "assignment", allowUserRole: ["admin","it","sale","sale_h","account","account_h","purchase","purchase_h"], "child_routes": [
          {"menu_title": "sidebar.contact_person", "path": "/"+moduleName+"/contact/person"},
        ]},
        {"menu_title": "sidebar.product", "menu_icon": "store", allowUserRole: ["admin","it","stock","stock_h","account","account_h","purchase","purchase_h"], "child_routes": [
          {"menu_title": "sidebar.product_PRD", "path": "/"+moduleName+"/product/PRD"},
          {"menu_title": "sidebar.product_PRD_uom", "path": "/"+moduleName+"/product/PRD_uom"},
          {"menu_title": "sidebar.product_PRD_brand", "path": "/"+moduleName+"/product/PRD_brand", allowUserRole: ["admin","it","stock_h","account","account_h","purchase","purchase_h"]},
          {"menu_title": "sidebar.product_PRD_good", "path": "/"+moduleName+"/product/PRD_good", allowUserRole: ["admin","it","stock_h","account","account_h","purchase","purchase_h"]},
          {"menu_title": "sidebar.product_PRD_category", "path": "/"+moduleName+"/product/PRD_category", allowUserRole: ["admin","it","stock_h","account","account_h","purchase","purchase_h"]},
          {"menu_title": "sidebar.product_PRD_type", "path": "/"+moduleName+"/product/PRD_type", allowUserRole: ["admin","it","stock_h","account","account_h","purchase","purchase_h"]},
          {"menu_title": "sidebar.product_PRD_model", "path": "/"+moduleName+"/product/PRD_model", allowUserRole: ["admin","it","stock_h","account","account_h","purchase","purchase_h"]},
        ]},

        {"menu_title": "sidebar.warehouse","menu_icon": "storage", allowUserRole:["admin","it","stock_h","account","account_h","purchase","purchase_h"],"child_routes": [
          {"menu_title": "sidebar.warehouse_location","path": "/"+moduleName+"/warehouse/location",},
          {"menu_title": "sidebar.warehouse_PRD_stock", "path": "/"+moduleName+"/warehouse/PRD_stock",},
          {"menu_title": "sidebar.warehouse_goodsadjust", "path": "/"+moduleName+"/warehouse/goodsadjust", allowUserRole: ["admin","it","stock_h","stock","account","account_h"]},
        ]},

        {"menu_title": "sidebar.promotion","menu_icon": "money-off", allowUserRole:["admin","it","sale_h","purchase","purchase_h"],"child_routes": [
          {"menu_title": "sidebar.sale_promotion","path": "/"+moduleName+"/sale/promotion",},
          {"menu_title": "sidebar.sale_promotiongroup","path": "/"+moduleName+"/sale/promotiongroup",},
        ]},

        {"menu_title": "sidebar.sale","menu_icon": "money-box", allowUserRole:["admin","it","sale_h","purchase","purchase_h"],"child_routes": [
          {"menu_title": "sidebar.settings_sale_area","path": "/"+moduleName+"/settings/sale_area",},
          {"menu_title": "sidebar.settings_coupon","path": "/"+moduleName+"/settings/coupon",},
        ]},

        {"menu_title": "sidebar.hr", "menu_icon": "male-female", allowUserRole: ["admin","hr","hr_h"], "child_routes": [
          {"menu_title": "sidebar.hr_staff", "path": "/"+moduleName+"/hr/staff",},
          {"menu_title": "sidebar.settings_department", "path": "/"+moduleName+"/settings/department", allowUserRole: ["admin"]},
          {"menu_title": "sidebar.settings_division", "path": "/"+moduleName+"/settings/division", allowUserRole: ["admin"]},
      //    {"menu_title": "sidebar.hr_timesheet", "path": "/"+moduleName+"/hr/timesheet",},
      //    {"menu_title": "sidebar.hr_payroll", "path": "/"+moduleName+"/hr/payroll",},
        ]},

        //  {"menu_title": "sidebar.asset_asm_supplies", "menu_icon": "store-24", allowUserRole: ["admin","it","stock","stock_h"], "child_routes": [
        //    {"menu_title": "sidebar.asset_AST_stock", "path": "/"+moduleName+"/asset/AST_stock",},
        //    {"menu_title": "sidebar.asset_SPY_stock", "path": "/"+moduleName+"/asset/SPY_stock",},
        //    {"menu_title": "sidebar.asset_AST", "path": "/"+moduleName+"/asset/AST", allowUserRole: ["admin","stock_h"]},
        //    {"menu_title": "sidebar.asset_SPY", "path": "/"+moduleName+"/asset/SPY", allowUserRole: ["admin","stock_h"]},
        //    {"menu_title": "sidebar.asset_AST_category", "path": "/"+moduleName+"/asset/AST_category", allowUserRole: ["admin","stock_h"]},
        //    {"menu_title": "sidebar.asset_SPY_category", "path": "/"+moduleName+"/asset/SPY_category", allowUserRole: ["admin","stock_h"]},
        //    {"menu_title": "sidebar.asset_AST_type", "path": "/"+moduleName+"/asset/AST_type", allowUserRole: ["admin","stock_h"]},
        //    {"menu_title": "sidebar.asset_SPY_type", "path": "/"+moduleName+"/asset/SPY_type", allowUserRole: ["admin","stock_h"]},
        //  ]},

      //  {"menu_title": "sidebar.purchase", "menu_icon": "assignment-returned", allowUserRole: ["admin","purchase","purchase_h"], "child_routes": [
      //    {"menu_title": "sidebar.purchase_po", "path": "/"+moduleName+"/purchase/po",},
      //  ]},
      //  {"menu_title": "sidebar.account", "menu_icon": "balance-wallet", allowUserRole: ["admin","account","account_h"], "child_routes": [
      //  ]},

        //{"menu_title": "sidebar.account_settings", "menu_icon": "account-o", "path": "/"+moduleName+"/account_settings"},
  		]
  	},
    operation: {
  		list: [
        //{"menu_title": "sidebar.sale_qt", "path": "/"+moduleName+"/sale/qt",},
        {"menu_title": "sidebar.sale_visit", "menu_icon": "flag", "path": "/"+moduleName+"/sale/visit", allowUserRole: ["admin","it","sale_h"]},
        //{"menu_title": "sidebar.sale_calendar", "menu_icon": "calendar", "path": "/"+moduleName+"/sale/calendar", allowUserRole: ["admin","it","sale_h"]},
        {"menu_title": "sidebar.warehouse_goodsrequest", "menu_icon": "collection-text", "path": "/"+moduleName+"/warehouse/goodsrequest", allowUserRole: ["admin","it","stock_h","stock","account","account_h"]},
        {"menu_title": "sidebar.sale_so", "menu_icon": "collection-text", "path": "/"+moduleName+"/sale/so", allowUserRole: ["admin","it","sale","sale_h"]},
        {"menu_title": "sidebar.warehouse_orderapprove_list", "path": "/"+moduleName+"/warehouse/orderapprove_list", allowUserRole: ["admin","it","stock_h","stock","account","account_h"]},
        {"menu_title": "sidebar.warehouse_picking_list", "path": "/"+moduleName+"/warehouse/picking_list", allowUserRole: ["admin","it","stock_h","stock","account","account_h"]},
        {"menu_title": "sidebar.warehouse_pickingconfirm_list", "path": "/"+moduleName+"/warehouse/pickingconfirm_list", allowUserRole: ["admin","it","stock_h","stock","account","account_h"]},
        {"menu_title": "sidebar.warehouse_invoice_list", "path": "/"+moduleName+"/warehouse/invoice_list", allowUserRole: ["admin","it","stock_h","stock","account","account_h"]},
        {"menu_title": "sidebar.warehouse_reprint_invoice", "path": "/"+moduleName+"/warehouse/reprint_invoice", allowUserRole: ["admin","account_h"]},
        {"menu_title": "sidebar.sale_inv", "path": "/"+moduleName+"/sale/inv", allowUserRole: ["admin","it","stock_h","stock","account","account_h"]},
        {"menu_title": "sidebar.account_collection","menu_icon": "format-list-checks", "icontype":"MaterialCommunityIcons", allowUserRole: ["admin","it","account","account_h"],"child_routes": [
            {"menu_title": "sidebar.account_invoicecollection", "path": "/"+moduleName+"/account/invoicecollection"},
            {"menu_title": "sidebar.account_billcollection", "path": "/"+moduleName+"/account/billcollection"},
        ]},
        {"menu_title": "sidebar.warehouse_goodstransfer", "menu_icon": "collection-text", "path": "/"+moduleName+"/warehouse/goodstransfer", allowUserRole: ["admin","it","stock_h","stock","account","account_h"]},
        {"menu_title": "sidebar.warehouse_goodsreturn", "menu_icon": "collection-text", "path": "/"+moduleName+"/warehouse/goodsreturn", allowUserRole: ["admin","it","stock_h","stock","account","account_h"]},
        {"menu_title": "sidebar.purchase_po", "menu_icon": "collection-text", "path": "/"+moduleName+"/purchase/po", allowUserRole: ["admin","it","stock_h","stock","account","account_h","purchase","purchase_h"]},
        {"menu_title": "sidebar.warehouse_goodsreceipt", "path": "/"+moduleName+"/warehouse/goodsreceipt", allowUserRole: ["admin","it","stock_h","stock","account","account_h","purchase","purchase_h"]},
        {"menu_title": "sidebar.purchase_rt", "path": "/"+moduleName+"/purchase/rt", allowUserRole: ["admin","it","stock_h","stock","account","account_h","purchase","purchase_h"]},
        //{"menu_title": "sidebar.operation_pr", "path": "/"+moduleName+"/operation/pr",},
        //{"menu_title": "sidebar.operation_eqr", "path": "/"+moduleName+"/operation/eqr",},
        //{"menu_title": "sidebar.operation_pdr", "path": "/"+moduleName+"/operation/pdr",},
        //{"menu_title": "sidebar.operation_ot", "path": "/"+moduleName+"/operation/ot",},
      ]
    },
    reports: {
  		list: [
        {"menu_title": "sidebar.report", "menu_icon": "file", "path": "/"+moduleName+"/report", allowUserRole:["admin","it","stock_h","stock","account","account_h","purchase","purchase_h"]},
      ]
    },
  },
  report_menu:{
    report: {
      list: [
        //{"menu_title": "sidebar.report.master", "menu_icon": "collection-bookmark", "child_routes" : [
        //    {"menu_title": "sidebar.report.master_customer","path": "report/master_customer", "size":"A2landscape", "exportfilename":"Customer Master"},
        //]},
        {"menu_title": "sidebar.report.sale", "menu_icon": "money", allowUserRole:["admin"], "child_routes" : [
          {"menu_title": "sidebar.report.sale_so_detail","path": "report/sale_so_detail", "size":"A2landscape", "exportfilename":"Saleorder"},
          {"menu_title": "sidebar.report.sale_by_brand","path": "report/sale_by_brand", "size":"A3landscape", "exportfilename":"SaleByBrand"},
          {"menu_title": "sidebar.report.promotion_by_brand","path": "report/promotion_by_brand", "size":"A3landscape", "exportfilename":"SaleByBrandPromotion"},
        ]},
        {"menu_title": "sidebar.report.promotion", "menu_icon": "money-off", allowUserRole:["admin"], "child_routes" : [
          {"menu_title": "sidebar.report.promotion_monthly_setting","path": "report/promotion_monthly_setting", "size":"A2landscape", "exportfilename":"รายงาน Monthly promotion setting"},
          //{"menu_title": "sidebar.report.promotion_summary","path": "report/promotion_summary", "size":"A2landscape", "exportfilename":"รายงานสรุปรายการโปรโมชั่น"},
          //{"menu_title": "sidebar.report.promotion_reward_by_shop","path": "report/promotion_reward_by_shop", "size":"A2landscape", "exportfilename":"รายงาน คูปอง แต้ม คงเหลือ รายร้านค้า"},
          //{"menu_title": "sidebar.report.promotion_reward_by_sale","path": "report/promotion_reward_by_sale", "size":"A2landscape", "exportfilename":"รายงาน ของแถม ส่วนลด คูปอง แต้ม คงเหลือ รายพนักงานขาย"},
          {"menu_title": "sidebar.report.promotion_by_code","path": "report/promotion_by_code", "size":"A3landscape", "exportfilename":"สรุปรายการโปรโมชั่นตามรหัส"},
          {"menu_title": "sidebar.report.promotion_by_product","path": "report/promotion_by_product", "size":"A3landscape", "exportfilename":"สรุปรายการโปรโมชั่นตามสินค้า"},
          {"menu_title": "sidebar.report.promotion_by_customer","path": "report/promotion_by_customer", "size":"A3landscape", "exportfilename":"สรุปรายการโปรโมชั่นตามร้านค้า"},
        ]},
        {"menu_title": "sidebar.report.visit", "menu_icon": "nature", allowUserRole:["admin"], "child_routes" : [
          {"menu_title": "sidebar.report.visit_summary_by_person","path": "report/visit_summary_by_person", "size":"A2landscape", "exportfilename":"รายงาน สรุปการเยี่ยมร้านค้า รายพนักงานขาย"},
          {"menu_title": "sidebar.report.visit_detail","path": "report/visit_detail", "size":"A2landscape", "exportfilename":"รายงาน การเยี่ยมร้านค้า"},
          {"menu_title": "sidebar.report.visit_customer_list","path": "report/visit_customer_list", "size":"A2landscape", "exportfilename":"รายงาน Customer list"},
          {"menu_title": "sidebar.report.visit_open_customer","path": "report/visit_open_customer", "size":"A2landscape", "exportfilename":"รายงานเปิดร้านค้าใหม่"},
        ]},
        {"menu_title": "sidebar.report.wms", "menu_icon": "storage", allowUserRole:["admin","it","stock_h","stock","account","account_h"], "child_routes" : [
          {"menu_title": "sidebar.report.wms_stock_balance","path": "report/wms_stock_balance", "size":"A3landscape", "exportfilename":"สต็อกสินค้าคงเหลือ"},
          {"menu_title": "sidebar.report.wms_stock_prepicking","path": "report/wms_stock_prepicking", "size":"A3landscape", "exportfilename":"Pre-Picking List"},
          {"menu_title": "sidebar.report.wms_stock_card","path": "report/wms_stock_card", "size":"A3landscape", "exportfilename":"StockCard"},
          {"menu_title": "sidebar.report.wms_stock_receipt","path": "report/wms_stock_receipt", "size":"A3landscape", "exportfilename":"StockReceipt"},
          {"menu_title": "sidebar.report.wms_stock_movement","path": "report/wms_stock_movement", "size":"A3landscape", "exportfilename":"StockMovement"},
          {"menu_title": "sidebar.report.wms_stock_transfer","path": "report/wms_stock_transfer", "size":"A3landscape", "exportfilename":"StockTransfer"},
          {"menu_title": "sidebar.report.wms_stock_adjust","path": "report/wms_stock_adjust", "size":"A3landscape", "exportfilename":"StockAdjust"},
          {"menu_title": "sidebar.report.wms_stock_return","path": "report/wms_stock_return", "size":"A2landscape", "exportfilename":"StockReturn"},
          //{"menu_title": "sidebar.report.wms_stock_cover","path": "report/wms_stock_cover", "size":"A3landscape", "exportfilename":"StockCoverDay"},
          {"menu_title": "sidebar.report.wms_stock_slow","path": "report/wms_stock_slow", "size":"A3landscape", "exportfilename":"StockSlowToNonMovement"},
          //{"menu_title": "sidebar.report.wms_stock_picking","path": "report/wms_stock_picking", "size":"A3landscape", "exportfilename":"สรุปใบหยิบสินค้า(โหลดสินค้า)"},
          {"menu_title": "sidebar.report.wms_stock_checking","path": "report/wms_stock_checking", "size":"A4landscape", "exportfilename":"ใบตรวจนับสินค้า"},
        ]},
      ]
    }
  }
}
